var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg ficha-generica",class:_vm.pedidoComputed.cobrado
      ? 'cobrada'
      : !!_vm.pedidoComputed.eliminado
      ? 'eliminada'
      : !!_vm.pedidoComputed.solicitarEliminado
      ? 'orange lighten-1'
      : '',attrs:{"elevation":"4","disabled":!!_vm.pedidoComputed.eliminado ||
    _vm.pedidoComputed.id == _vm.$store.state.impresion.pedidoOcupado},on:{"click":_vm.goPedido}},[_c('v-responsive',{staticClass:"d-flex rounded-0",attrs:{"aspect-ratio":1.618}},[_c('v-img',{attrs:{"src":"/img/fondoFichaFunEats.svg"}},[_c('v-card-text',{staticClass:"pa-0",staticStyle:{"position":"relative"}},[_c('v-list',{staticClass:"pb-0 pt-0 pt-md-2",attrs:{"color":"transparent"}},[_c('v-list-item',{staticClass:"pl-0 pr-16"},[_c('v-list-item-content',{staticClass:"py-0 align-self-start"},[_c('v-list-item-title',{staticClass:"px-2 px-lg-3 text-uppercase font-weight-black black--text text-lg-h5",class:_vm.$vuetify.breakpoint.xsOnly
                    ? 'py-1 body-2'
                    : _vm.$vuetify.breakpoint.mdAndDown
                    ? 'body-2'
                    : 'title'},[_vm._v(" "+_vm._s(_vm.nombreMostrar)+" ")]),(_vm.pedidoComputed.nombrePersonalizadoTpv)?_c('v-list-item-subtitle',{staticClass:"px-2 pl-lg-3 pr-lg-10 text-lg-h6 mt-n2 mt-md-0 font-weight-black cyan--text",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption' : ''},[_vm._v(" "+_vm._s(_vm.pedidoComputed.nombrePersonalizadoTpv)+" ")]):_vm._e()],1)],1)],1),(_vm.pedido.numPedidoRestDia)?_c('div',{staticClass:"card--numero-pedido px-2 px-lg-3 text-lg-h2 text-sm-h3 text-h4 mt-lg-2 font-weight-black cyan--text text--lighten-2",style:(_vm.$vuetify.breakpoint.smAndDown
              ? 'font-size: 2.5rem !important;'
              : _vm.$vuetify.breakpoint.lgAndUp
              ? 'font-size: 5rem !important;'
              : '')},[(_vm.pedido.numPedidoRestDia <= 9)?_c('span',[_vm._v("0")]):_vm._e(),_vm._v(_vm._s(_vm.pedido.numPedidoRestDia)+" ")]):_vm._e()],1),_c('v-card-subtitle',{staticClass:"px-2 mt-n3 mt-md-n1 mt-lg-n3 font-weight-medium",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption pt-0' : 'title',style:(_vm.$vuetify.breakpoint.xsOnly ? 'margin-top: -4px;' : '')},[_vm._v(" "+_vm._s(_vm.nombreClienteMostrar))]),_c('div',{staticClass:"warning-status"},[(_vm.pedidoComputed.estadoCocina == 'parado')?_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '22' : '32',"color":"yellow darken-4"}},[_vm._v(" mdi-pause ")]):_vm._e(),(
            _vm.pedidoComputed.estado == 'finalizado' &&
            _vm.pedidoComputed.impresionLocalEstado != 'impreso' &&
            _vm.pedidoComputed.printNodeEstado != 'imprimido'
          )?_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '22' : '32',"color":"red lighten-1"}},[_vm._v(" mdi-printer ")]):_vm._e()],1),_c('v-card-actions',{staticClass:"mt-4 acciones-footer"},[_c('v-row',{staticClass:"pb-1",attrs:{"align":"end"}},[_c('v-col',{staticClass:"py-0",class:_vm.$vuetify.breakpoint.xsOnly ? 'px-2' : '',attrs:{"cols":"6"}},[(_vm.comensales)?_c('div',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '22',"color":"blue-grey lighten-1"}},[_vm._v(" mdi-account-outline ")]),_c('span',{staticClass:"mr-1 font-weight-medium black--text",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'},[_vm._v(_vm._s(_vm.comensales))])],1):_vm._e(),_c('div',{staticClass:"text-no-wrap mt-n2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '12' : '22',"color":"blue-grey lighten-1"}},[_vm._v(" mdi-clock-time-eight-outline ")]),_c('span',{staticClass:"font-weight-medium black--text",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'},[_vm._v(_vm._s(_vm.pedidoComputed.fechaMostrarContador))])],1),(
                _vm.pedidoComputed.modo == 'domicilio' &&
                _vm.$vuetify.breakpoint.smAndUp
              )?_c('div',{staticClass:"text-no-wrap mt-n2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '16' : '28',"color":"blue-grey lighten-1"}},[_vm._v(" mdi-moped-outline ")]),_c('span',{staticClass:"font-weight-medium black--text",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'},[_vm._v(_vm._s(_vm.pedidoComputed.horaEntrega)+"h")])],1):_vm._e(),(_vm.pedidoComputed.horaCobrado && _vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"text-no-wrap mt-n2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":_vm.$vuetify.breakpoint.mdAndDown ? '16' : '28',"color":"blue-grey lighten-1"}},[_vm._v(" mdi-cash-multiple ")]),_c('span',{staticClass:"font-weight-medium black--text",class:_vm.$vuetify.breakpoint.mdAndDown ? 'caption' : 'subtitle-1'},[_vm._v(_vm._s(_vm.pedidoComputed.horaCobrado)+"h")])],1):_vm._e()]),_c('v-col',{staticClass:"py-0 px-2 pr-lg-3 text-right",attrs:{"cols":"6"}},[_c('span',{staticClass:"font-weight-black text-no-wrap",class:_vm.$vuetify.breakpoint.xsOnly
                  ? 'px-1'
                  : _vm.$vuetify.breakpoint.mdAndDown
                  ? 'body-1'
                  : 'title'},[_vm._v(_vm._s(_vm.totalMostrar)+"€")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }