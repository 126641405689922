var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown,"overlay-opacity":"0.90","max-width":"600","scrollable":"","content-class":_vm.$vuetify.breakpoint.mdAndUp
      ? 'mt-n4 dialogo--custom-fs elevation-0'
      : 'dialogo--custom-fs'},model:{value:(_vm.accionesDialog),callback:function ($$v) {_vm.accionesDialog=$$v},expression:"accionesDialog"}},[_c('v-card',{staticClass:"transparent",class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card-title',{staticClass:"py-6 transparent"},[_c('v-btn',{class:_vm.$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4',attrs:{"fab":"","height":_vm.$vuetify.breakpoint.smAndDown ? 48 : 88,"width":_vm.$vuetify.breakpoint.smAndDown ? 48 : 88,"color":"primary","absolute":"","right":""},on:{"click":function($event){_vm.accionesDialog = false}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? 36 : 54,"color":"black"}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card-title',{staticClass:"text--primary rounded-t-lg blue-grey lighten-5",class:_vm.$vuetify.breakpoint.smAndDown
          ? 'caption'
          : _vm.$vuetify.breakpoint.mdAndDown
          ? 'body-2'
          : 'body-1',staticStyle:{"height":"78px"}},[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"info"}},[_vm._v(" mdi-information-outline ")]),_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" ACCIONES PEDIDO - "+_vm._s(_vm.tituloPidiendo)+" "+_vm._s(_vm.numeroPidiendo)+" ")])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{staticClass:"mr-n2 mt-n1",attrs:{"fab":"","height":"48","width":"48","color":"primary","absolute":"","right":""},on:{"click":function($event){_vm.accionesDialog = false}}},[_c('v-icon',{attrs:{"size":"36","color":"black"}},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4 blue-grey lighten-5",class:_vm.pedidoEditandoObj.fechaMostrar || _vm.pedidoEditandoObj.copyUsuarioTpv
          ? 'rounded-0'
          : 'rounded-b-lg'},[_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.pararEnCocina()}}},[_c('v-icon',{attrs:{"color":"yellow darken-4","big":""}},[_vm._v("mdi-move")])],1),(_vm.pedidoEditandoObj.estadoCocina != 'parado')?_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.pararEnCocina()}}},[_c('v-icon',{attrs:{"color":"yellow darken-4","big":""}},[_vm._v("mdi-pause")])],1):_vm._e(),(_vm.pedidoEditandoObj.estadoCocina == 'parado')?_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":function($event){return _vm.reanudarEnCocina()}}},[_c('v-icon',{attrs:{"color":"green darken-4","big":""}},[_vm._v("mdi-play")])],1):_vm._e()],1),(
        _vm.pedidoEditandoObj.fechaMostrar || _vm.pedidoEditandoObj.copyUsuarioTpv
      )?[_c('v-divider'),_c('v-card-actions',{staticClass:"py-3 blue-grey lighten-5",class:_vm.$vuetify.breakpoint.mdAndUp ? 'rounded-b-lg' : 'rounded-0'},[_c('v-row',[(_vm.pedidoEditandoObj.fechaMostrar)?_c('v-col',{staticClass:"pb-0 pb-sm-3",attrs:{"cols":"12","sm":"6"}},[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"size":"18"}},[_vm._v(" mdi-clock-outline ")]),_vm._v("Registrado a las "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("  "+_vm._s(_vm.pedidoEditandoObj.fechaMostrar)+" ")])],1):_vm._e(),(
              _vm.pedidoEditandoObj.copyUsuarioTpv &&
              _vm.pedidoEditandoObj.copyUsuarioTpv.nombre
            )?_c('v-col',{staticClass:"text-sm-right pt-0 pt-sm-3",attrs:{"cols":"12","sm":"6","offset-sm":!_vm.pedidoEditandoObj.fechaMostrar ? 6 : 0}},[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"size":"18"}},[_vm._v(" mdi-account-box ")]):_vm._e(),_vm._v("Atendido por "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("  "+_vm._s(_vm.pedidoEditandoObj.copyUsuarioTpv.nombre)+" ")])],1):_vm._e()],1)],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }