<template>
  <div
    style="height: 100%"
    class="blue-grey lighten-5"
    id="inspire"
  >
    <v-app-bar
      height="78"
      app
      dark
    >
      <v-toolbar-title class="">
        <!-- @click="$router.push(modosMesas[0].enlace)" -->
        <v-btn
          @click="
            $router.push(modosMesas[0].enlace);
          tab = 1;
          "
          class="px-0 py-8 fe--inicio-btn"
          max-width="100%"
        >
          <v-img
            class="pa-0"
            src="/img/logoFunEats.svg"
            lazy-src="/img/logoFunEats.svg"
            contain
            width="150"
            height="30"
          ></v-img>
        </v-btn>
      </v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-btn
        class="rounded-lg"
        large
        outlined
        color="primary"
        @click="$store.commit('usuarios/setEleccionUsuarioDialog', true)"
      >
        <v-icon left>mdi-account-check</v-icon>
        {{ $store.state.usuarios.usuarioActualObj.nombre }}
      </v-btn>
      <!-- Aquí iba la hora -->
      <v-btn
        class="rounded-lg ml-4"
        large
        outlined
        color="primary"
        @click="dialogoSoporte = true"
      >
        <v-icon>mdi-whatsapp</v-icon>
        <!--<span v-if="!$vuetify.breakpoint.smAndDown"> SOPORTE </span>-->
      </v-btn>
      <v-btn
        class="rounded-lg mx-4"
        large
        outlined
        color="primary"
        @click="dialogoManual = true"
      >
        <v-icon>mdi-file-pdf-box</v-icon>
        <!--<span v-if="!$vuetify.breakpoint.smAndDown"> MANUAL </span>-->
      </v-btn>
      <v-btn
        v-if="totalZ"
        class="rounded-lg"
        large
        outlined
        color="primary"
        @click="cerrarDia()"
      >
        <!--<span class="mr-3">{{ totalZ }}</span>-->
        <v-icon>mdi-currency-eur</v-icon>
        <!--<span v-if="!$vuetify.breakpoint.smAndDown"> MANUAL </span>-->
      </v-btn>
      <!--<span class="mx-2">{{ $store.state.contable.numerosContables }}</span>
      -
      <span class="mx-2">{{ $store.state.pedidos.siguienteNumRest }}</span
      >-->

      <!-- <v-card>
					<v-list-item-group v-model="usuarioSeleccionado">
						<v-list-item
							color="success"
							v-for="usuario in $store.getters['usuarios/getUsuariosListado']"
							:key="usuario.id"
							:value="usuario.id"
						>
							<v-list-item-content>
								<v-list-item-title class="title">{{
									usuario.nombre
								}}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list-item-group>
				</v-card> -->

      <v-spacer />
      <span
        v-if="!!$store.state.directorio.directorioMatrizObj"
        class="font-weight-medium text-uppercase d-none d-md-flex"
      >{{ $store.state.directorio.directorioMatrizObj.nombre }}
      </span>
      <span
        v-else
        class="font-weight-medium text-uppercase d-none d-md-flex"
      >{{ negocioObj.nombre }} - {{ nombre }}</span>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>

      <v-btn
        v-if="$store.state.online"
        large
        class="rounded-lg"
        outlined
        color="primary"
        @click="menuLateral = !menuLateral"
      >
        Online
        <v-icon right>mdi-menu-down</v-icon>
      </v-btn>
      <v-btn
        v-else
        large
        class="rounded-lg"
        outlined
        color="red"
        @click="menuLateral = !menuLateral"
      >
        Offline
      </v-btn>

      <!-- <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn large class="rounded-lg" color="red" dark @click="logout">
        <v-icon size="34" dark>mdi-power</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-sheet
      class="subheader-nav--sticky"
      color="primary"
    >
      <div class="d-flex elevation-3">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          class="modos-tabs"
          show-arrows
          center-active
          slider-size="8"
          slider-color="teal lighten-1"
          active-class="font-weight-black teal lighten-4"
          slider
          height="78"
          prev-icon="mdi-chevron-left"
          next-icon="mdi-chevron-right"
          style="max-width: calc(100% - 78px)"
        >
          <v-tab
            class="black--text d-none px-0"
            @click="$router.push('/dashboard')"
          >INICIO</v-tab>
          <v-tab
            v-for="(modoMesa, i) in modosMesas"
            :key="i"
            class="px-0 justify-center"
          >
            <router-link
              :to="modoMesa.enlace"
              class="black--text px-2"
            >
              <span>
                {{ modoMesa.nombre }}
              </span>
            </router-link>
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-divider vertical></v-divider>
        <v-menu
          bottom
          left
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              width="78"
              height="78"
              :style="!$store.state.tab ? 'background-color: #b2dfdb' : ''"
            >
              <v-icon size="30">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list light>
            <v-list-item-group color="secundary">
              <v-list-item
                class="py-2"
                @click="ultimopedido"
              >
                <v-list-item-icon>
                  <v-icon color="black"> mdi-receipt </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Último pedido cobrado</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="py-2"
                @click="$router.push('/busquedapedidos')"
              >
                <v-list-item-icon>
                  <v-icon color="black"> mdi-format-list-bulleted </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Todos los pedidos del día</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="py-2"
                @click="$router.push('/dashboard')"
              >
                <v-list-item-icon>
                  <v-icon color="black"> mdi-cog </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Configuración</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </v-sheet>

    <v-main>
      <v-container
        fluid
        class
      >
        <router-view />
      </v-container>
    </v-main>

    <v-footer
      height="78"
      dark
      app
      class="pa-0"
    >
      <footerComponent />
    </v-footer>
    <notificacion-dialog id="notificacines" />
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      overlay-opacity="0.90"
      :content-class="$vuetify.breakpoint.mdAndUp
        ? 'mt-n4 dialogo--custom-fs elevation-0 dialogo--no-fs'
        : 'dialogo--custom-fs elevation-0 dialogo--no-fs'
        "
      id="tiempoForzadoCierre"
      v-model="tiempoForzadoCierreDialog"
      max-width="600px"
      scrollable
    >
      <v-card
        class="transparent"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'rounded-0'"
      >
        <v-card-title
          v-if="$vuetify.breakpoint.mdAndUp"
          class="py-6 transparent"
        ><v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 88"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 88"
            color="primary"
            @click="tiempoForzadoCierreDialog = false"
            absolute
            right
            :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : 'mt-10 mr-n4'"
          ><v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 54"
              color="black"
            >mdi-close</v-icon></v-btn></v-card-title>
        <v-card-title
          :class="$vuetify.breakpoint.smAndDown
            ? 'caption'
            : $vuetify.breakpoint.mdAndDown
              ? 'title rounded-t-lg'
              : 'display-1 rounded-t-lg'
            "
          class="text--primary blue-grey lighten-5"
        >
          ¿Cuanto tiempo quiere cerrar
          {{
            tiempoForzadoCierreModo ? tiempoForzadoCierreModo.join(" y ") : ""
          }}?
          <v-spacer></v-spacer>
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            fab
            height="48"
            width="48"
            color="primary"
            @click="tiempoForzadoCierreDialog = false"
            absolute
            right
            class="mr-n2"
          ><v-icon
              size="36"
              color="black"
            >mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="rounded-b-lg pt-4 blue-grey lighten-5 border">
          <v-row
            align="center"
            class="white--text"
          >
            <v-col
              cols="6"
              md="3"
            >
              <v-card
                color="green"
                class="white--text text-center"
                @click="elegirTiempoCierre(15)"
                rounded="lg"
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center justify-center text-button"
                >
                  <div>15 min</div>
                </v-responsive>
              </v-card>
            </v-col>
            <v-col
              cols="6"
              md="3"
            >
              <v-card
                color="orange"
                class="white--text text-center"
                @click="elegirTiempoCierre(30)"
                rounded="lg"
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center justify-center text-button"
                >
                  <div>30 min</div>
                </v-responsive>
              </v-card>
            </v-col>
            <v-col
              cols="6"
              md="3"
            >
              <v-card
                color="red"
                class="white--text text-center"
                @click="elegirTiempoCierre(60)"
                rounded="lg"
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center justify-center text-button"
                >
                  <div>1 hora</div>
                </v-responsive>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-btn
                x-large
                color="black"
                width="100%"
                height="150px"
                class="white--text"
                @click="elegirTiempoCierre(60 * 4)"
              >4 horas</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <eleccion-usuario-dialog />
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="dialogoSoporte"
      max-width="400"
      overlay-opacity="0.90"
      content-class="elevation-0"
    >
      <v-card
        class="transparent"
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
      >
        <v-card-title class="white py-3 pr-3">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Soporte
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="dialogoSoporte = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="blue-grey lighten-5 pt-4 rounded-b-lg">
          Escanea el siguiente código QR para contactar con nosotros.
          <div class="d-flex justify-center mt-8">
            <!-- <vue-qr text="https://vuetifyjs.com/en/components/alerts/" size="200"></vue-qr> -->
            <v-img
              src="/img/QRwsb.jpeg"
              max-width="200"
              max-height="200"
            >
            </v-img>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="codigoSeguridadCierreDialog"
      max-width="400"
      overlay-opacity="0.90"
      content-class="elevation-0"
    >
      <v-card
        class="transparent"
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
      >
        <v-card-title class="white py-3 pr-3">
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Código de seguridad
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="codigoSeguridadCierreDialog = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="blue-grey lighten-5 pt-4 rounded-b-lg">
          <v-text-field
            autofocus
            outlined
            ref="codigoSeguridadRef"
            label="Código de seguridad"
            clearable
            v-model="codigoSeguridadCierre"
            type="number"
          />

          <v-btn
            class="mt-6 black--text"
            x-large
            rounded
            color="primary"
            width="100%"
            @click="cambioConfirmarCodigoSeguridadCierreOnline"
          >Confirmar</v-btn>

        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      v-model="dialogoManual"
      max-width="400"
      overlay-opacity="0.90"
      content-class="elevation-0"
    >
      <v-card
        class="transparent"
        :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'"
      >
        <v-card-title class="white py-3 pr-3">
          <!-- <v-icon color="info" class="mr-4"> mdi-history </v-icon> -->
          <h2
            :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'body-1'"
            class="font-weight-black"
          >
            Manual
          </h2>
          <v-spacer />
          <v-btn
            fab
            :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
            :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
            color="primary"
            @click="dialogoManual = false"
            top
            right
          >
            <v-icon
              :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
              color="black"
            >mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="blue-grey lighten-5 pt-4 rounded-b-lg">
          Escanea el siguiente código QR para leer el manual, o pincha el botón
          para verlo en el TPV.
          <div class="d-flex justify-center mt-8">
            <vue-qr
              text="https://funeats.page.link/FETPV"
              :size="200"
            ></vue-qr>
          </div>
          <v-btn
            class="mt-6 black--text"
            x-large
            rounded
            color="primary"
            href="https://funeats.page.link/FETPV"
            target="_blank"
            width="100%"
          >Ver manual</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-btn
      v-if="$store.state.mostrarFabAdd"
      fab
      height="54"
      width="54"
      bottom
      left
      color="secondary"
      fixed
      @click="$store.commit('setMostrarTeclado', true)"
      ><v-icon size="54" color="black">mdi-keyboard</v-icon></v-btn
    > -->
    <v-btn
      v-if="$store.state.mostrarFabAdd"
      fab
      height="108"
      width="108"
      bottom
      right
      color="primary"
      fixed
      @click="abrirDialogoAnadir = true"
    ><v-icon
        size="54"
        color="black"
      >mdi-plus</v-icon></v-btn>
    <!-- @click="$router.push('/pedido/')" -->
    <cargando-lazy />
    <!--<generando-catalogo />-->
    <impresion />
    <contable />
    <cobrar-dialog />
    <cliente-dialog />
    <anadir-dialogo />
    <calendario-dialogo />
    <keyboard-completo-bottom />
    <v-navigation-drawer
      v-model="menuLateral"
      fixed
      right
      temporary
      width="440"
    >
      <v-list class="py-0">
        <v-list-item
          style="background-color: #455a64"
          dense
        >
          <v-list-item-content class="py-0">
            <v-list-item-title class="title white--text font-weight-black">
              Online
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            class="my-0"
            @click.stop="cambio(['domicilio', 'recoger'])"
          >
            <v-switch
              inset
              readonly
              :loading="estado.loading"
              v-model="estadoGeneral"
              color="white"
            ></v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-row class="mb-0">
          <v-col
            cols="6"
            class="pt-6 pl-6"
          >
            <v-text-field
              class="cyan accent-1 font-weight-bold centered-input rounded-lg"
              readonly
              label="TIEMPO DOMICILIO"
              :value="tiempoDomicilio + ' min'"
              outlined
              hide-details
              dense
            ></v-text-field>
            <v-row
              class="mt-3"
              no-gutters
            >
              <v-col
                cols="6"
                class="pr-2"
              >
                <v-btn
                  dark
                  block
                  @click="sumarRestar('resta', 'reparto')"
                  class="rounded-lg"
                >
                  <v-icon size="34">mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="pl-2"
              >
                <v-btn
                  dark
                  block
                  @click="sumarRestar('suma', 'reparto')"
                  class="rounded-lg"
                >
                  <v-icon size="34">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-text-field
              class="cyan accent-1 font-weight-bold centered-input rounded-lg"
              readonly
              label="TIEMPO RECOGIDA"
              :value="tiempoRecogida + ' min'"
              outlined
              hide-details
              dense
            ></v-text-field>
            <v-row
              class="mt-3"
              no-gutters
            >
              <v-col
                cols="6"
                class="pr-2"
              >
                <v-btn
                  dark
                  block
                  @click="sumarRestar('resta', 'recogida')"
                  class="rounded-lg"
                >
                  <v-icon size="34">mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="6"
                class="pl-2"
              >
                <v-btn
                  dark
                  block
                  @click="sumarRestar('suma', 'recogida')"
                  class="rounded-lg"
                >
                  <v-icon size="34">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1">DOMICILIO</v-list-item-title>
                <v-list-item-subtitle
                  v-for="(item, i) in horariosDomicilio"
                  class="text--primary"
                  :key="`${i}_domicilio`"
                >{{ item }}</v-list-item-subtitle>
                <h5
                  v-if="restObj.modoHorarioDomicilio == 'forzado_cierre' &&
                    restObj.modoHorarioDomicilioTiempo > 0
                    "
                  class="warning--text"
                >
                  Cerrado hasta {{ restObj.modoHorarioDomicilioTiempoMostrar }}
                </h5>
              </v-list-item-content>
              <v-list-item-action @click.stop="cambio(['domicilio'])">
                <v-switch
                  inset
                  readonly
                  :loading="estado.loading"
                  class="my-2"
                  v-model="estadoDomicilio"
                  color="blue-grey darken-2"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              class="mb-4"
              dense
            >
              <v-list-item-content>
                <v-list-item-title class="subtitle-1">RECOGER</v-list-item-title>
                <v-list-item-subtitle
                  v-for="(item, i) in horariosRecoger"
                  class="text--primary"
                  :key="`${i}_recoger`"
                >{{ item }}</v-list-item-subtitle>
                <h5
                  v-if="restObj.modoHorarioRecogerTiempo"
                  class="warning--text"
                >
                  Cerrado hasta {{ restObj.modoHorarioRecogerTiempoMostrar }}
                </h5>
              </v-list-item-content>
              <v-list-item-action @click.stop="cambio(['recoger'])">
                <v-switch
                  inset
                  readonly
                  :loading="estado.loading"
                  class="my-2"
                  v-model="estadoRecoger"
                  color="blue-grey darken-2"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-container
          fluid
          style="position: absolute; bottom: 0"
        >
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="abrirCajon"
                :loading="loadingZ"
                color="primary black--text"
                x-large
                block
                class="rounded-lg"
              >Abrir cajón</v-btn>
            </v-col>
            <!-- <v-col cols="12">
              <v-btn
                @click="abrirCajon"
                :loading="loadingZ"
                dark
                x-large
                block
                class="rounded-lg"
                >Abrir cajón 2</v-btn
              >
            </v-col> -->
          </v-row>
          <v-row
            class="mt-0"
            v-if="!$store.state.zcierre"
          >
            <v-col cols="9">
              <!-- <v-divider class="mb-4"></v-divider> -->
              <v-btn
                @click="imprimirz"
                :loading="loadingZ"
                dark
                x-large
                block
                class="rounded-lg"
              >Imprimir Z</v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="imprimirzAyer"
                :loading="loadingZ"
                dark
                x-large
                block
                class="rounded-lg"
              >AYER</v-btn>
            </v-col>
          </v-row>
          <v-row
            class="mt-0"
            v-else
          >
            <v-col cols="6">
              <v-btn
                @click="imprimirz"
                :loading="loadingZ"
                dark
                x-large
                block
                class="rounded-lg"
              >Imprimir Z</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :disabled="!$store.state.zcierre"
                @click="cerrarDia"
                :loading="loadingZ"
                dark
                x-large
                block
                class="rounded-lg"
              >Cerrar día</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                @click="salidaDinero"
                dark
                x-large
                block
                class="rounded-lg"
              >Salida dinero</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list>
    </v-navigation-drawer>
    <info-producto-dialogo />
    <z-cierre-inicio />
    <z-cierre-fin />
    <salida-dinero-dialog />
  </div>
</template>

<script>
import panelLateral from "@/components/panelLateral.vue";
import panelPedidosInternos from "@/components/pedidosInternos_panelLateral.vue";
import footerComponent from "@/components/footerComponent.vue";
import notificacionDialog from "@/components/NotificacionDialogo.vue";
import eleccionUsuarioDialog from "@/components/EleccionUsuarioDialog.vue";
import cargandoLazy from "@/components/CargandoLazy.vue";
import GenerandoCatalogo from "@/components/GenerandoCatalogo.vue";
import Impresion from "@/components/Impresion.vue";
import Contable from "@/components/Contable.vue";
import CobrarDialog from "@/components/CobrarDialog.vue";
import ClienteDialog from "@/components/ClienteDialog.vue";
import anadirDialogo from "@/components/AnadirDialogo.vue";
import infoProductoDialogo from "@/components/InfoProductoDialogo.vue";
import { mapState, mapActions } from "vuex";
import { arrayHorariosRest } from "../utils/dias.utils.js";
import { mostrarIdioma, mostrarMoneda } from "@/utils/helper.utils.js";
import KeyboardCompletoBottom from "@/components/KeyboardCompletoBottom";
import calendarioDialogo from "@/components/CalendarioDialogo.vue";
import zCierreInicio from "@/components/ZCierreInicio.vue";
import zCierreFin from "@/components/ZCierreFin.vue";
import SalidaDineroDialog from "@/components/SalidaDineroDialog.vue";

import moment from "moment";
import VueQr from "vue-qr";
import { parDiasComparar } from "@/utils/dias.utils.js";

export default {
  components: {
    VueQr,
    Impresion,
    Contable,
    GenerandoCatalogo,
    KeyboardCompletoBottom,
    panelLateral,
    panelPedidosInternos,
    footerComponent,
    notificacionDialog,
    eleccionUsuarioDialog,
    cargandoLazy,
    CobrarDialog,
    ClienteDialog,
    anadirDialogo,
    calendarioDialogo,
    infoProductoDialogo,
    zCierreInicio,
    zCierreFin,
    SalidaDineroDialog,
  },
  data() {
    return {
      codigoSeguridadCierre: '',
      codigoSeguridadCierreDialog: false,
      // tab: 0,
      menuUsuario: false,
      loadingNeo: false,
      estado: {
        loading: false,
      },
      menuLateral: false,
      general: true,
      online: true,
      recoger: true,
      menuNeo: false,
      menuDirectorio: false,
      tiempoForzadoCierreDialog: false,
      tiempoForzadoCierreModo: null, //domicilio, recoger, online
      tiempoForzadoCierreEleccion: null, //el tiempo elegido
      checkboxFunEats: true,
      checkboxJustEat: false,
      checkboxUberEats: false,
      checkboxGlovo: false,
      checkboxMesaLibre: true,
      checkboxMesaOcupada: false,
      agregadores: [
        {
          title: "Fun Eats",
          src: "/img/fondoFichaFunEats.png",
          color: "#56a7a8",
        },
        {
          title: "Glovo",
          src: "/img/fondoFichaGlovo.png",
          color: "#f9c52e",
        },
        {
          title: "Just Eat",
          src: "/img/fondoFichaJustEat.png",
          color: "#f27700",
        },
        {
          title: "Uber Eats",
          src: "/img/fondoFichaUberEats.png",
          color: "#4fc83d",
        },
      ],
      // de panelLateral.vue
      loadingZ: false,
      cargandoRecogidaActivada: false,
      cargandoDomicilioActivado: false,
      cargandoTiempoDomicilio: false,
      cargandoTiempoRecogida: false,
      tiempoBloqueo: 2000,
      dialogoSoporte: false,
      dialogoManual: false,
      // hasta aquí panelLateral.vue
    };
  },
  watch: {
    tab(newV, oldV) {
      console.log(newV);
      if (newV == this.modosMesas.length + 1) {
        this.actualizarVtab();
      }

      //ultimo pedido
    },
    pistolaTextoDefinitivo(v) {
      if (v.length > 0 && !this.$store.state.pedidos.pedidoDialog) {
        this.buscarPorCodigo(v)
          .then(() => {
            // this.$store.commit("setPistolaTextoDefinitivo", "");
          })
          .finally(() => {
            this.$store.commit("setPistolaTextoDefinitivo", "");
          });
      } else {
      }
      // this.$store.commit("setPistolaTextoDefinitivo", "");
    },
    // de panelLateral.vue
    tiempoDomicilio: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoDomicilio) {
        this.cargandoTiempoDomicilio = true;

        setTimeout(function () {
          _vue.setTiempoRecogida(_vue.tiempoRecogida);
          _vue.setTiempoDomicilio(_vue.tiempoDomicilio);
          _vue.cargandoTiempoDomicilio = false;
        }, this.tiempoBloqueo);
      }
    },
    tiempoRecogida: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoRecogida) {
        this.cargandoTiempoRecogida = true;
        setTimeout(function () {
          _vue.setTiempoRecogida(_vue.tiempoRecogida);
          _vue.setTiempoDomicilio(_vue.tiempoDomicilio);
          _vue.cargandoTiempoRecogida = false;
        }, this.tiempoBloqueo);
      }
    },
    // hasta aquí panelLateral.vue
    tiempoForzadoCierreDialog(v) {
      if (!v) {
        if (this.tiempoForzadoCierreEleccion == null) {
          this.$forceUpdate();
        }
      }
    },
  },
  computed: {
    tab: {
      get() {
        return this.$store.state.tab;
      },
      set(v) {
        this.$store.commit("setTab", v);
      },
    },
    totalZ() {
      if (this.$store.state.zcierreResumen.cobrados) {
        return this.$store.state.zcierreResumen &&
          this.$store.state.zcierreResumen.cobrados.totales.total
          ? mostrarMoneda(
            this.$store.state.zcierreResumen.cobrados.totales.total
          )
          : false;
      }
    },
    // de panelLateral.vue
    tiempoDomicilio: {
      get() {
        return this.$store.state.tiempoDomicilio;
      },
      set(v) {
        this.$store.commit("setTiempoDomicilio", v);
      },
    },
    tiempoRecogida: {
      get() {
        return this.$store.state.tiempoRecogida;
      },
      set(v) {
        this.$store.commit("setTiempoRecogida", v);
      },
    },
    // hasta aquí panelLateral.vue
    ...mapState(["pistolaTextoDefinitivo"]),
    ...mapState({
      restObjBruto: (state) => state.restauranteObj,
      negocioObj: (state) => state.negocioObj,
      isLoaded: (state) => state.loaded,
    }),
    modosMesas() {
      return this.$store.state.datosTpv.modos
        .filter((m) => !!m.tipoModo)
        .map((m) => {
          var link = "";
          switch (m.tipoModo) {
            case "domicilio":
              link = "/pedidos/domicilio/" + m.id;
              break;
            case "recoger":
              link = "/pedidos/recoger/" + m.id;
              break;
            case "mostrador":
              link = "/pedidos/mostrador/" + m.id;
              break;
            case "mesas":
              link = "/mesas/" + m.id;
              break;
          }
          return {
            ...m,
            enlace: link,
          };
        });
    },
    abrirDialogoAnadir: {
      get() {
        return this.$store.state.pedido.pedidoDialog;
      },
      set(v) {
        this.$store.commit("pedidos/setPedidoDialog", v);
      },
    },
    usuarioSeleccionado: {
      get() {
        return this.$store.state.usuarios.usuarioActual;
      },
      set(v) { },
    },
    fechaNeo() {
      return this.restObjBruto && this.restObjBruto.fechaActualizacionNeo
        ? moment(this.restObjBruto.fechaActualizacionNeo.toDate()).format(
          "HH:mm DD-MM-YYYY"
        )
        : "";
    },
    estadoDomicilio: {
      get() {
        return this.$store.state.domicilioActivado;
      },
      set(v) { },
    },
    estadoRecoger: {
      get() {
        return this.$store.state.recogidaActivada;
      },
      set(v) { },
    },
    estadoGeneral: {
      get() {
        return this.estadoDomicilio || this.estadoRecoger;
      },
      set(v) { },
    },
    horariosDomicilio() {
      const a = arrayHorariosRest(this.restObj);
      var items = "";
      if (Array.isArray(a) && Array.isArray(a[0])) {
        var items = a[0].map((rango) => rango.inicio + " - " + rango.fin);
      }

      return items;
    },
    horariosRecoger() {
      const a = arrayHorariosRest(this.restObj);
      var items = "";
      if (Array.isArray(a) && Array.isArray(a[1])) {
        var items = a[1].map((rango) => rango.inicio + " - " + rango.fin);
      }
      return items;
    },
    domicilioAbierto() {
      return this.restObj.horariosComputados[0];
    },
    recogidaAbierta() {
      return this.restObj.horariosComputados[1];
    },

    restObj() {
      return {
        ...this.restObjBruto,
        modoHorarioRecogerTiempoMostrar: moment
          .unix(this.restObjBruto.modoHorarioRecogerTiempo)
          .format("HH:mm"),
        modoHorarioDomicilioTiempoMostrar: moment
          .unix(this.restObjBruto.modoHorarioDomicilioTiempo)
          .format("HH:mm"),
      };
    },
    nombre() {
      return this.restObj.nombre;
    },
    horariosComputados() {
      return this.restObj.horariosComputados;
    },
  },
  methods: {
    salidaDinero() {
      this.$store.commit("setSalidaDineroDialog", true);
    },
    ultimopedido() {
      this.$store.dispatch("pedidos/ultimopedidoMostrarCobrar").then((r) => {
        if (r) {
          this.$store.commit("pedidos/setCobrarDialog", true);

          this.pedidoEditando = r;
          this.$store.dispatch("pedidos/setPedidoObj", r);
        }
      });
    },
    buscarPorCodigo(v) {
      if (v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO_DEFECTO"]
            .filter((p) => {
              return (
                !!p.codigoLector &&
                p.codigoLector.toUpperCase().includes(v.toUpperCase())
              );
            })
            .map((p) => {
              return {
                ...p,
                nombre: mostrarIdioma(p.nombre),
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        return f.then((r) => {
          if (r[0]) {
            this.$store.commit("setInfoProductoObj", r[0]);
            this.$store.commit("setInfoProductoDialogo", true);
          }
        });
        this.loadingBuscar = true;
      } else {
        return Promise.resolve();
        // this.resultadosBusqueda = [];
      }
    },
    cerrarDia() {
      this.$store.commit("setZCierreFinDialog", true);
      // this.$store.dispatch("finZCierre");
    },
    // de panelLateral.vue
    ...mapActions({
      setRecogidaActivada: "setRecogidaActivada",
      setDomicilioActivado: "setDomicilioActivado",
      setTiempoDomicilio: "setTiempoDomicilio",
      setTiempoRecogida: "setTiempoRecogida",
    }),
    abrirCajon() {
      let _vm = this;
      _vm.loadingZ = true;
      this.$store
        .dispatch("abrirCajon")
        .then((r) => {
          if (!r.ok) {
            return Promise.reject(r.error);
          }
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Se ha mandado a imprimir el Z",
          });
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al imprimir el Z: " + e,
          });
        })
        .finally(() => {
          _vm.loadingZ = false;
        });
    },
    imprimirz() {
      let _vm = this;
      _vm.loadingZ = true;
      this.$store
        .dispatch("calcularCierre")
        .then(() => {
          return this.$store.dispatch("imprimirz");
        })
        .then((r) => {
          /*this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Se ha mandado a imprimir el Z",
          });*/
        })

        .finally(() => {
          _vm.loadingZ = false;
        });
    },
    async imprimirzAyer() {
      let _vm = this;
      _vm.loadingZ = true;
      const fechaMoment = moment().subtract(1, "day").clone();
      const fechaMomentEnviar = moment().subtract(1, "day").clone();

      var pedidos = await this.$store.dispatch(
        "pedidos/getPedidosOtroDia",
        parDiasComparar(fechaMoment)
      );
      this.$store
        .dispatch("calcularCierre", pedidos)
        .then(() => {
          return this.$store.dispatch("imprimirzAyer", {
            pedidos: pedidos,
            fecha: fechaMomentEnviar,
          });
        })
        .then((r) => {
          /*this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Se ha mandado a imprimir el Z",
          });*/
        })

        .finally(() => {
          _vm.loadingZ = false;
        });
    },
    sumarRestar(operacion, tipo) {
      let minutos = 5;
      switch (tipo) {
        case "reparto":
          operacion == "suma"
            ? (this.tiempoDomicilio += minutos)
            : this.tiempoDomicilio - minutos >= 0
              ? (this.tiempoDomicilio -= minutos)
              : null;

          break;
        default:
          operacion == "suma"
            ? (this.tiempoRecogida += minutos)
            : this.tiempoRecogida - minutos >= 0
              ? (this.tiempoRecogida -= minutos)
              : null;

          break;
      }
    },
    // hasta aquí panelLateral.vue
    actualizarNeoRestaurante() {
      var _vm = this;
      _vm.loadingNeo = true;

      this.$store
        .dispatch("neo/actualizarNeoRestaurante")
        .then(() => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Productos sincronizados",
          });
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al sincronizar " + e,
          });
        })
        .finally(() => {
          _vm.loadingNeo = false;
        });
    },
    cambiarEstadoDomicilio(v) {
      var _vm = this;
      this.$store.commit("setDomicilioActivado", v);
      this.$store
        .dispatch("setDomicilioActivado", {
          v: v,
          t: _vm.tiempoForzadoCierreEleccion,
        })
        .then(() => {
          this.$store.commit("setDomicilioActivado", v);
        })
        .catch((e) => { })
        .finally(() => {
          _vm.estado.loading = false;
        });
    },
    cambiarEstadoRecoger(v) {
      var _vm = this;
      this.$store.commit("setRecogidaActivada", v);
      this.$store
        .dispatch("setRecogidaActivada", {
          v: v,
          t: _vm.tiempoForzadoCierreEleccion,
        })
        .then(() => {
          this.$store.commit("setRecogidaActivada", v);
        })
        .catch((e) => { })
        .finally(() => {
          _vm.estado.loading = false;
        });
    },
    cambio(v) {
      this.tiempoForzadoCierreModo = v;
      var _vm = this;
      if (
        (v.includes("domicilio") && this.estadoDomicilio) ||
        (v.includes("recoger") && this.estadoRecoger)
      ) {
        // va a cerrar
        if (this.$store.state.configuracionTpv && this.$store.state.configuracionTpv.codigoSeguridadCierreOnline) {
          this.codigoSeguridadCierre = '';
          this.codigoSeguridadCierreDialog = true;
        } else {
          this.tiempoForzadoCierreEleccion = null;
          this.tiempoForzadoCierreDialog = true;
        }

      } else {
        this.tiempoForzadoCierreEleccion = null;
        if (v.includes("domicilio")) {
          _vm.cambiarEstadoDomicilio(true);
        }
        if (v.includes("recoger")) {
          _vm.cambiarEstadoRecoger(true);
        }
      }
    },
    cambioConfirmarCodigoSeguridadCierreOnline() {
      if (this.codigoSeguridadCierre === this.$store.state.configuracionTpv.codigoSeguridadCierreOnline) {
        this.tiempoForzadoCierreEleccion = null;
        this.codigoSeguridadCierreDialog = false
        this.tiempoForzadoCierreDialog = true;
      } else {
        this.codigoSeguridadCierre = ''
        this.$refs.codigoSeguridadRef.focus()
      }
    },
    /* ...mapActions({
      logout: "auth/firebaseLogout",
    }), */
    volver() {
      this.$router.go(-1);
    },
    elegirTiempoCierre(t) {
      this.tiempoForzadoCierreEleccion = t;
      this.tiempoForzadoCierreModo.forEach((m) => {
        if (m == "domicilio") this.cambiarEstadoDomicilio(false);
        if (m == "recoger") this.cambiarEstadoRecoger(false);
      });
      this.tiempoForzadoCierreModo = [];
      this.tiempoForzadoCierreDialog = false;
    },
    actualizarVtab() {
      let path = this.$route.path;
      switch (path) {
        case "/dashboard":
          this.tab = this.modosMesas.length + 3;
          break;
        case "/busquedapedidos":
          this.tab = this.modosMesas.length + 2;
          break;
        default:
          let fI = this.modosMesas.findIndex(
            (m) => m.enlace == this.$route.path
          );
          this.tab = fI + 1;
      }
    },
  },
  mounted() {
    this.actualizarVtab();

    this.$store.dispatch("cargaDespuesInicio");
    this.$store.commit("setModoFuncionamiento", "tpv");
  },
};
</script>

<style lang="scss">
.fe--inicio-btn,
.usuarios-btn {
  >.v-btn__content {
    max-width: 100%;
  }
}

.subheader-nav--sticky {
  position: sticky;
  top: 78px;
  z-index: 5;
}

.border-primary.hora-cabecera {
  padding-top: 3px;
  padding-bottom: 3px;
  border: solid 1px #00f4d9 !important;
}

.modos-tabs {
  a {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 90px;
    height: 100%;
    text-align: center;
    text-decoration: none;

    >span {
      width: 100%;
    }
  }

  /* .v-tab--active {
    &::before {
      opacity: .1 !important;
      background-color: black;
    }
  } */
}

/** Fix de wordbreak en v-card-title **/
/* .v-card__text, */
.v-card__title {
  word-break: normal;

  /* maybe !important  */
  &.caption {
    line-height: 1.4;
  }
}
</style>