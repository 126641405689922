var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"rounded-lg ficha-generica",style:(hover ? 'cursor: pointer' : ''),attrs:{"disabled":_vm.disabled,"rounded":!!_vm.esMenu,"elevation":"4","color":!!_vm.seleccionado ? 'primary' : ''},on:{"click":function($event){return _vm.$emit('accion')}}},[(!!_vm.cantidad)?_c('v-chip',{staticClass:"custom-badge producto-badge elevation-3 info caption font-weight-bold white--text",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.cantidad)+" ")]):_vm._e(),(!!_vm.precio)?_c('v-chip',{staticClass:"custom-badge producto-price elevation-3 font-weight-bold black--text d-flex justify-center",class:!!_vm.seleccionado ? 'white' : 'primary',attrs:{"label":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.precio)+" ")]):_vm._e(),_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-black text-center",class:_vm.$vuetify.breakpoint.smAndDown || !!_vm.muchasOpciones
            ? 'caption pa-1'
            : _vm.$vuetify.breakpoint.mdAndDown
            ? 'body-2'
            : 'body-2',style:(_vm.$vuetify.breakpoint.smAndDown || !!_vm.muchasOpciones
            ? 'line-height:1;'
            : 'line-height:1.2;')},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(_vm.$vuetify.breakpoint.lgAndDown ? 3 : 7),expression:"$vuetify.breakpoint.lgAndDown ? 3 : 7"}],staticStyle:{"word-break":"normal !important"},style:(_vm.$vuetify.breakpoint.mdAndDown
                ? 'max-height: 48px;'
                : _vm.$vuetify.breakpoint.lgAndDown
                ? 'max-height: 56px;'
                : 'max-height: 120px;')},[_vm._v(" "+_vm._s(_vm.texto)+" ")])],1),(!!_vm.tieneOpciones || !!_vm.esMenu)?_c('div',{staticClass:"opciones-bg"},[(!!_vm.tieneOpciones)?_c('v-icon',{attrs:{"color":"info lighten-1"}},[_vm._v("mdi-cog")]):(!!_vm.esMenu)?_c('v-icon',{attrs:{"color":"orange lighten-2"}},[_vm._v("mdi-silverware-variant")]):_vm._e()],1):_vm._e()],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }